<template>
  <div class="container">
    <div class="social-container">
      <div class="row">
        <div class="col-12 col-lg">
          <h2 class="social-title">Мы в соц. сетях</h2>
          <p class="social-text">
            Вы можете задать вопросы не только нам, но и реальным пользователям
            аппарата в социальных сетях.
          </p>
        </div>
        <div class="col-12 col-md-6 col-lg-auto mb-3 mb-md-0">
          <div class="social-card">
            <div class="title tg text-center">
              <img src="../assets/social/tg/logo.jpg" class="me-2" alt="" />
              DomSpirt в Telegram
            </div>
            <div class="images">
              <img src="../assets/social/tg/user1.png" class="img-tg" alt="" />
              <img src="../assets/social/tg/user2.png" class="img-tg" alt="" />
              <img src="../assets/social/tg/user3.png" class="img-tg" alt="" />
              <img src="../assets/social/tg/user4.png" class="img-tg" alt="" />
              <img src="../assets/social/tg/user5.png" class="img-tg" alt="" />
              <img src="../assets/social/tg/user6.png" class="img-tg" alt="" />
            </div>
            <div class="members-tg">1 736 участников</div>
            <a
              href="https://t.me/domspirt"
              target="_blank"
              class="btn-social tg"
            >
              <img src="../assets/social/tg/tg-logo.svg" class="me-2" alt="" />
              Перейти
            </a>
          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-auto">
          <div class="social-card">
            <div class="title text-center">
              <img src="../assets/social/vk/logo.png" class="me-2" alt="" />
              Дистилляторы DomSpirt
            </div>
            <div class="images">
              <img class="img-vk" src="../assets/social/vk/user1.png" alt="" />
              <img class="img-vk" src="../assets/social/vk/user2.png" alt="" />
              <img class="img-vk" src="../assets/social/vk/user3.png" alt="" />
              <img class="img-vk" src="../assets/social/vk/user4.png" alt="" />
              <img class="img-vk" src="../assets/social/vk/user5.png" alt="" />
              <img class="img-vk" src="../assets/social/vk/user6.png" alt="" />
            </div>
            <div class="members-tg">2 470 подписчиков</div>
            <a
              href="https://vk.com/domspirt"
              target="_blank"
              class="btn-social vk"
            >
              <img src="../assets/social/vk/vk-logo.svg" alt="" class="me-2" />
              Перейти в сообщество
            </a>
          </div>

          <!--          <div id="vk_groups"></div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SocialBlock",
  // mounted() {
  //   window.VK.Widgets.Group(
  //     "vk_groups",
  //     {
  //       mode: 3,
  //       width: 300,
  //       height: 200,
  //       color1: "373737",
  //       color2: "ffffff",
  //       color3: "5181B8",
  //     },
  //     215675005
  //   );
  // },
};
</script>

<style scoped lang="scss">
.social-container {
  @media (min-width: 768px) {
    background: linear-gradient(
      129.22deg,
      rgba(21, 21, 22, 0.9) 0%,
      rgba(50, 50, 51, 0.9) 97.03%
    );
    border-radius: 12px;
    padding: 40px;
  }
  @media (max-width: 768px) {
    margin-top: 30px;
  }
}
.social-title {
  font-weight: 700;
  font-size: 42px;
  line-height: 52px;
  background-image: linear-gradient(to left, #ffa353, #d95e00);
  color: transparent;
  /* purgecss start ignore */
  background-clip: text;
  -webkit-background-clip: text;
  text-shadow: 0 0 transparent;
  /* purgecss end ignore */
  @media (max-width: 768px) {
    text-align: center;
    font-size: 28px;
    line-height: 34px;
    margin-top: 30px;
  }
}
.social-text {
  color: #c4c4c4;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  @media (max-width: 768px) {
    text-align: center;
    line-height: 24px;
  }
}
.social-card {
  min-width: 300px;

  background: #373737;
  border-radius: 12px;
  padding: 0 13px 13px 13px;
  .title {
    border-bottom: 1px solid #4d4d4d;
    padding: 13px 0;
    //&.tg {
    //  padding: 13px 0;
    //}

    img {
      width: 34px;
      border-radius: 50%;
    }
  }
  .images {
    display: flex;
    justify-content: center;
    padding: 13px 0;
    .img-vk {
      margin: 0 4.5px;
      width: 35px;
    }
    .img-tg {
      margin: 0 4.5px;
      width: 35px;
    }
  }
  .members-tg {
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    margin-bottom: 14px;
  }
  .members-vk {
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;

    margin-top: 13px;
  }
  .btn-social {
    background: black;
    border-radius: 8px;
    text-align: center;
    font-size: 13px;
    line-height: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    color: #fff;
    transition: all 0.3s;

    &.tg {
      background-color: #229ed9;
      &:hover {
        background-color: darken(#229ed9, 10%);
      }
    }

    &.vk {
      background-color: #0077ff;
      &:hover {
        background-color: darken(#0077ff, 10%);
      }
    }

    &:hover {
      text-decoration: none;
      transform: translateY(-1px);
    }
  }
}
</style>
